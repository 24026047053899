import { gql } from 'graphql-request'

export const COLLECTIONS_PAGES = gql`
  query GetCollections($first: Int!, $after: String) {
    collections(first: $first, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
      }
    }
  }
`

export const COLLECTIONS = gql`
  query GetCollections($first: Int!, $after: String) {
    collections(first: $first, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          metafields(
            identifiers: [
              { namespace: "custom_fields", key: "product_swatches" }
              { namespace: "custom", key: "rug_design" }
              { namespace: "global", key: "title_tag" }
              { namespace: "global", key: "description_tag" }
              { namespace: "custom", key: "collection_custom_image" }
              { namespace: "custom", key: "collection_custom_image_url" }
              { namespace: "custom", key: "rugcollectionlinkorder" }
              { namespace: "custom", key: "pile_height_filter" }
              { namespace: "custom", key: "size_filter" }
              { namespace: "custom", key: "header_description" }
              { namespace: "custom", key: "show_prize" }
              { namespace: "custom", key: "custom_heading" }
              { namespace: "custom", key: "color_filter" }
              { namespace: "custom", key: "fiber_filter" }
              { namespace: "custom", key: "shape_filter" }
              { namespace: "custom", key: "design_filter" }
              { namespace: "custom", key: "style_filter" }
              { namespace: "custom", key: "noindex" }
            ]
          ) {
            key
            value
            namespace
            reference {
              ... on MediaImage {
                image {
                  originalSrc: url
                }
              }
            }
          }

          id
          description
          descriptionHtml
          handle
          title
          image {
            originalSrc: url
            srcLargeDesktop: url(transform: { maxWidth: 2048 })
            srcDesktopMedium: url(transform: { maxWidth: 1600 })
            srcDesktop: url(transform: { maxWidth: 1024 })
            srcMobile: url(transform: { maxWidth: 768 })
          }
          seo {
            title
            description
          }
          products(first: 250) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              cursor
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`
